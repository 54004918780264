<template>
  <main style="margin-top: 100px !important;">
    <b-modal
        v-model="showPaymentDetailsModal"
        hide-header
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
    >
      <form ref="paymentForm" id="payment-form" @submit="paymentSubmit">
        <div id="payment-element">
          <!-- Elements will create form elements here -->
        </div>
        <div id="error-message" style="color: red">
          <!-- Display error message to your customers here -->
        </div>
        <b-button class="mt-2 mb-2" style="width: 100%" type="submit" block variant="primary">Plateste in siguranta</b-button>
        <b-button style="width: 100%" @click="showPaymentDetailsModal = false" type="button" block variant="secondary">Anuleaza</b-button>
      </form>
    </b-modal>
    <b-modal
        id="modal-image-product"
        v-model="showProductImage"
        hide-header
        :footer-bg-variant="footerBgVariant"
      >
      <b-img class="img-fluid w-100" :src="currentProductModal.image_url"></b-img>
      <template #modal-footer="{cancel}">
        <div class="modal-footer-custom">
          <b class="text-white">{{currentProductModal.name}} - <strong>{{ toCurrency(currentProductModal.price) }}</strong></b>
          <b-button size="sm" variant="danger" @click="cancel()">
            <strong>X</strong>
          </b-button>
        </div>
        </template>
    </b-modal>
    <b-modal
        id="modal-options-product"
        v-model="showProductFeatures"
        hide-header
        footer-bg-variant="white"
        class="zoom-anim-dialog"
    >
      <div class="content">
        <h5>{{ selectedProduct.name }}</h5>
        <h6>{{ selectedProduct.description }}</h6>
        <p>Pret: {{ toCurrency(selectedProductTotalPrice) }}</p>
        <hr>
        <div class="product-options-wrapper">
          <div class="product-feature"
              v-for="(feature, index) in selectedProduct.features"
              :key="index"
          >
          <!-- RADIOS -->

           <b-form-group class="feature-label"
           label-size="sm"
           :description="checkRequiredFeature(feature)"
           :label="feature.label" 
           v-slot="{ ariaDescribedby }"
           v-if="feature.max === 1">
            <b-form-radio
              v-for="(option, optionIndex) in feature.options"
              v-bind:class="{ lastOption: optionIndex+1 === feature.options.length }"
              @change="featureRadioChanged(feature.id, option.name)"
              :key="optionIndex"
              v-model="option.checked"
              :value="true"
              :aria-describedby="ariaDescribedby"
              :name="feature.name.replace(' ', '')"
            >
              {{ option.label }}<span> +{{ toCurrency(option.price) }}</span>
            </b-form-radio>
          </b-form-group>

            <!-- CHECKBOXES OR MULTIPLE -->

            <b-form-group class="feature-label" 
            :description="checkRequiredFeature(feature)"
             :label="feature.label" v-slot="{ ariaDescribedby }" v-if="feature.max >= 2">
              <div class="form-checkboxes" v-for="(option, optionIndex) in feature.options"
                  :key="optionIndex" v-bind:class="{ lastOption: optionIndex+1 === feature.options.length }">
                   <b-form-checkbox
                    :aria-describedby="ariaDescribedby"
                    :id="option.id"
                    v-model="option.checked"
                    :name="feature.name.replace(' ', '')"
                    v-if="!option.multiple"
                    class="checkboxed-btn"
                  >
                    {{ option.label }}<span> +{{ toCurrency(option.price) }}</span>
                  </b-form-checkbox>

                <div class="form-group-option option-multiple" v-if="option.multiple">
                  <div class="option-label">{{ option.label }} <span> +{{ toCurrency(option.price) }}</span></div>
                  <div class="wrapper-qty">
                      <b-button squared @click="changeQtyOptionFeature(feature, option, -1)" class="btn-qty" variant="outline-secondary"><i class="mdi mdi-minus" /></b-button>
                      <b-button squared disabled variant="outline-secondary" class="qty">{{option.qty}}</b-button>
                      <b-button squared @click="changeQtyOptionFeature(feature, option, 1)" class="btn-qty" variant="outline-secondary"><i class="mdi mdi-plus" /></b-button>
                  </div>
                  <b-form-input v-show="false" class="qty-feature" :min="0" :max="option.max" type="number" v-model="option.qty" />
                </div>
              </div>
            </b-form-group>

          </div>
        </div>
      </div>
      <template #modal-footer="{}">
        <div class="modal-footer-custom">
          <b-button variant="outline-secondary" @click="showProductFeatures = false">Inchide</b-button>
          <b-button variant="outline-success" @click="addProductFeaturesToBasket">Adauga in cos</b-button>
        </div>
      </template>
    </b-modal>
    <b-container v-if="Object.keys(restaurant).length" class="p-2">
      <b-row style="margin: 0;" class="text-center" align-v="center">
        <b-col md="12" class="strip">
          <figure>
            <b-img :src="restaurant.image_url" fluid></b-img>
            <a class="strip_info">
              <div v-if="restaurant.text_promo" id="text-promo">{{restaurant.text_promo}}</div>
              <div class="item_title">
                <h3 style="font-size: 2rem;">{{ restaurant.name }}</h3>
                <small>Adresa: {{ restaurant.street }}, {{ restaurant.zip }} {{ restaurant.city }}</small><br>
                <small>Telefon: <a style="color: white !important;" :href="'tel:'+restaurant.telefon">{{ restaurant.telefon }}</a>
                </small> - <small>Timp estimat: {{restaurant.estimated_time}}</small>
              </div>
            </a>
          </figure>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row v-if="Object.keys(restaurant).length">
        <b-col xl="8">
          <b-tabs
              ref="categoriesTabsHeader"
              fill
              content-class="col-md-12 p-0"
              pills
              align="center"
              :nav-wrapper-class="windowTop >= 500 ? 'col-md-12 card p-0 mb-1 bg-light border-bottom categoriesTabs' : 'col-md-12 card p-0 mb-1 border-bottom bg-light '"
              class="m-0"
              card
          >
            <b-tab class="p-0" v-for="(products, category) in restaurant.categories" :key="category">
              <template #title>
                <span @click="scrollToCategory" class="font-weight-bold">{{ category }}</span>
              </template>
              <div class="list_menu" id="list_menu">
                <section>
                  <div class="table_wrapper">
                    <table class="table cart-list menu-gallery">
                      <thead>
                      <tr>
                        <th>Produs</th>
                        <th style="width: 75px;">Pret</th>
                        <th style="width: 25px;"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in products" :key="index">
                        <td class="d-md-flex align-items-center productsFirstTDMaxWidth">
                          <figure id="image-product" @click="showProductImageModal(item)">
                            <b-img :src="item.image_url"></b-img>
                          </figure>
                          <div class="flex-md-column">
                            <h4>{{ item.name }}</h4>
                            <div style="white-space: pre-line;">
                              {{ item.description }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <strong>{{ toCurrency(item.price) }}</strong>
                        </td>
                        <td class="options">
                          <b-link @click="addToBasket(item)"><i class="icon_plus_alt2"></i></b-link>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col xl="4">
          <div>
            <div class="container margin_detail" v-if="Object.keys(restaurant).length">
              <b-row v-if="restaurant.delivery_active">
                <b-col md="12">
                  <div class="box_order">
                    <div class="head">
                      <div class="title">
                        <h3>Sumar Comanda</h3>
                      </div>
                    </div>
                    <div class="main">
                      <ul
                        v-if="restaurant.min_order_amount_value > 0"
                        class="clearfix"
                      >
                        <li>
                          <b-alert show variant="danger" v-if="basketTotals.subtotal < restaurant.min_order_amount_value">
                            Comanda minima: <span class="">{{ toCurrency(restaurant.min_order_amount_value) }}</span>
                          </b-alert>
                          <b-alert show v-else variant="success">
                            Comanda minima: <span class="">{{ toCurrency(restaurant.min_order_amount_value) }}</span>
                          </b-alert>
                        </li>
                      </ul>

                      <div class="wrapper-cart">
                        <div class="product-cart" v-for="(item, index) in basket" :key="index">
                          <div class="product-details">
                            <div><b-link class="item-name" @click="removeFromBasket(item, index)"><span>{{item.qty}} x {{ item.name }}</span><i class="mdi mdi-delete" /></b-link></div>
                            <div v-for="(feature, ind) in item.features" :key="ind">
                                <div v-for="(opt, ix) in feature.options" :key="ix">
                                  <div v-if="opt.checked">
                                    <div v-if="!opt.multiple">{{1 * item.qty}} x {{opt.label}} <span class="price">{{toCurrency(opt.price)}}</span></div>
                                    <div v-else-if="opt.multiple">{{opt.qty * item.qty}} x {{opt.label}} <span class="price">{{toCurrency(opt.price * opt.qty)}}</span></div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div class="qty-total">
                            <div class="wrapper-qty">
                                <b-button squared @click="changeQtyBasket(index, -1)" class="btn-qty" variant="outline-secondary"><i class="mdi mdi-minus" /></b-button>
                                <b-button squared disabled variant="outline-secondary" class="qty">{{item.qty}}</b-button>
                                <b-button squared @click="changeQtyBasket(index, 1)" class="btn-qty" variant="outline-secondary"><i class="mdi mdi-plus" /></b-button>
                            </div>
                            <div class="total-price-item"><span>{{ toCurrency(item.price) }}</span></div>
                          </div>
                        </div>
                      </div>

                      <ul class="clearfix">
                        <li v-if="orderMode === '0'">Subtotal<span>{{ toCurrency(basketTotals.subtotal) }}</span></li>
                        <li v-if="orderMode === '0'">Taxa livrare<span>{{ toCurrency(restaurant.delivery_tax) }}</span></li>
                        <li v-if="voucherResponse && !voucherError && this.basket.length > 0">
                          <i @click="voucherResponse = null" class="mdi mdi-delete" style="cursor: pointer;"></i>
                          Reducere cupon:
                          <span v-if="voucherResponse.value_type === 'fixed'">-{{ voucherResponse.value }} RON</span>
                          <span v-else>-{{ voucherResponse.value }} %</span>
                        </li>
                        <li class="total">Total<span>{{ toCurrency(basketTotals.total) }}</span></li>
                      </ul>
                      <div class="row opt_order" v-if="ridicarePersonala">
                        <div class="col-12">
                          <label class="container_radio">Livrare
                            <input id="delivery" type="radio" v-model="orderMode" value="0">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-12">
                          <label class="container_radio">Ridicare personala
                            <input id="take_away" type="radio" v-model="orderMode" value="1">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <hr v-if="!voucherResponse && this.basket.length > 0">
                      <div class="row" v-if="!voucherResponse && this.basket.length > 0">
                        <div class="col-12">
                          <div class="form-group">
                            <label>Cupon reducere</label>
                            <b-input type="text" @keyup="voucherError = null" v-model="voucherCode" placeholder="Introdu codul de reducere"></b-input>
                          </div>
                        </div>
                        <div class="col-12" v-if="voucherError">
                          <b-alert show variant="danger">{{ voucherError }}</b-alert>
                        </div>
                        <div class="col-12">
                          <b-button type="button" @click="addVoucher" class="btn_1 gradient full-width">Adauga cuponul</b-button>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="col-12">
                          <b-img :src="csyToken" fluid width="50"></b-img>In curand plata prin <span style="color: #5078C2;font-weight: bold;">CSY TOKEN</span>
                        </div>
                      </div>
                      <div class="row opt_order">
                        <div class="col-6" v-if="restaurant.accept_cash_payment">
                          <label class="container_radio">Plata cash
                            <input v-model="paymentMode" v-bind:value="'cash'" type="radio">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-6" v-if="restaurant.accept_online_payment">
                          <label class="container_radio">Plata online
                            <input v-model="paymentMode" v-bind:value="'card'" type="radio">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div class="btn_1_mobile">
                        <b-button v-if="userInfo !== null && !isBusy && userInfo.verified" type="button" @click="orderNow" class="btn_1 gradient full-width">
                          Comanda acum
                        </b-button>
                        <b-button v-if="userInfo === null && !isBusy" type="button" @click="orderNow" class="btn_1 gradient full-width">Intra in cont pentru a putea comanda</b-button>
                        <b-button v-if="userInfo !== null && isBusy" type="button" variant="secondary" class="btn_1 gradient full-width">
                          Se trimite comanda...
                        </b-button>

                        <b-row v-if="userInfo !== null && userInfo.verified === null && !verificationMailSent">
                          <b-col md="12" class="text-center">
                            <b-alert variant="danger" show>Adresa de mail nu este verificata. Pentru a putea comanda te rugam sa apesi click pe link-ul primit in E-Mail</b-alert>
                          </b-col>
                          <b-col md="12">
                            <b-button variant="primary" @click="sendVerificationMail()">RETRIMITE E-MAILUL DE VERIFICARE</b-button>
                          </b-col>
                        </b-row>
                        <b-row v-if="verificationMailSent && userInfo.verified === null">
                          <b-col md="12" class="text-center">
                            <b-alert variant="success" show>Te rugam sa verifici adresa de email si sa apesi pe link-ul de activare</b-alert>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col md="12" v-if="userInfo !== null">
                  <div class="box_order_form">
                    <div class="head">
                      <div class="title">
                        <h3>Informatii Comanda</h3>
                      </div>
                    </div>
                    <div class="main">
                      <div class="form-group">
                        <label>Prenume</label>
                        <b-input type="text" v-model="orderDetails.firstName" placeholder="Prenume (obligatoriu)"></b-input>
                      </div>
                      <div class="form-group">
                        <label>Nume</label>
                        <b-input type="text" v-model="orderDetails.lastName" placeholder="Nume (obligatoriu)"></b-input>
                      </div>
                      <div class="form-group">
                        <label>Strada, nr</label>
                        <gmap-autocomplete
                            ref="address_input_autocomplete"
                            class="form-control"
                            placeholder="Strada si nr (obligatoriu)"
                            @place_changed="setLocation"
                            @keyup="locationSelected = false"
                        >
                        </gmap-autocomplete>
                      </div>
                      <div class="form-group" v-if="locationSelected">
                        <label>Bloc, Apartament, etc.</label>
                        <b-input type="text" v-model="orderDetails.address_extra"></b-input>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Oras</label>
                            <b-input type="text" v-model="orderDetails.city" placeholder="Oras (obligatoriu)"></b-input>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Cod Postal</label>
                            <b-input type="text" v-model="orderDetails.zip" placeholder="Nu este obligatoriu"></b-input>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Telefon</label>
                        <VuePhoneNumberInput
                          @update="checkPhoneOrder"
                          :only-countries="countries"
                          :countries-height="40"
                          color="#ccc"
                          valid-color="#6aa84f"
                          error-color="#ff0000"
                          :translations="translatePhone"
                          no-flags
                          required
                          no-country-selector
                          default-country-code="RO"
                          v-model="numarTel" />
                      </div>
                      <div class="form-group" v-if="!restaurant.is_opened && restaurant.next_open_day">
                        <b-alert show variant="danger">Urmatoarea livrare se poate face pe data de {{ formatDate(restaurant.next_open_day.date) }}</b-alert>
                        <label>Cand doresti livrarea precomenzii ?</label>
                        <b-form-select v-model="preorderTime" :options="preorderTimes" size="sm" class="form-control"></b-form-select>
                      </div>
                      <div class="form-group">
                        <label>Mentiuni comanda</label>
                        <b-textarea v-model="orderDetails.customer_notes" />
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col md="12">
                  <b-alert show variant="danger">In momentul de fata, acest restaurant nu poate accepta comenzi noi</b-alert>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!--
    <div class="bg_gray" v-if="Object.keys(restaurant).length && !restaurant.is_opened">
      <div class="container">
        <b-alert variant="warning" show>In acest moment restaurantul este inchis</b-alert>
      </div>
      <b-container>
        <b-row>
          <b-col md="12">
            <div class="box_order_form">
              <div class="head text-center">
                <h3><i class="mdi mdi-clock"></i> Program de lucru</h3>
              </div>
              <div class="main" v-if="restaurant.opening_hours">
                <b-row class="p-2 border-bottom">
                  <b-col xs="12" md="4">
                    Luni
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].monday_from }}
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].monday_to }}
                  </b-col>
                </b-row>
                <b-row class="p-2 border-bottom">
                  <b-col xs="12" md="4">
                    Marti
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].tuesday_from }}
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].tuesday_to }}
                  </b-col>
                </b-row>
                <b-row class="p-2 border-bottom">
                  <b-col xs="12" md="4">
                    Miercuri
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].wednesday_from }}
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].wednesday_to }}
                  </b-col>
                </b-row>
                <b-row class="p-2 border-bottom">
                  <b-col md="4">
                    Joi
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].thursday_from }}
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].thursday_to }}
                  </b-col>
                </b-row>
                <b-row class="p-2 border-bottom">
                  <b-col md="4">
                    Vineri
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].friday_from }}
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].friday_to }}
                  </b-col>
                </b-row>
                <b-row class="p-2 border-bottom">
                  <b-col md="4">
                    Sambata
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].saturday_from }}
                  </b-col>
                  <b-col xs="6">
                      {{ restaurant.opening_hours[0].saturday_to }}
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col md="4">
                    Duminica
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].sunday_from }}
                  </b-col>
                  <b-col xs="6">
                    {{ restaurant.opening_hours[0].sunday_to }}
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    -->
  </main>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { isEqual } from 'lodash'

import PublicRestaurants from '@/components/publicEndpoints/restaurants'
import Orders from '@/components/publicEndpoints/orders'
import Auth from '@/components/auth'
import { bus } from "../../main";
import axios from "axios";
export default {
  components: {
    VuePhoneNumberInput
  },
  created: function() {
    if (this.userInfo !== null) {
      this.numarTel = this.userInfo.phone;
    }
  },
  data() {
    return {
      translatePhone: {
        countrySelectorLabel: 'Codul tarii',
        countrySelectorError: 'Alege o tara',
        phoneNumberLabel: 'Telefon',
        example: 'Tel :'
      },
      countries: ['RO'],
      userInfo: Auth.userInfo(),
      locationSelected: false,
      isBusy: false,
      restaurant: {},
      basket: [],
      cartIds: [],
      error: false,
      windowTop: 0,
      orderMode: '0',
      extra_delivery_confirmed: false,
      sms_code: null,
      verificationMailSent: false,
      userCheckTimeout: null,
      paymentMode: 'cash',
      stripe: null,
      showPaymentDetailsModal: false,
      payElements: null,
      paymentConfirmed: false,
      paymentIntentId: null,
      preorderTime: "",
      currentProductModal: '',
      showProductImage: false,
      footerBgVariant: 'dark',
      showProductFeatures: false,
      selectedProduct: false,
      selectedProductInitialState: {},
      csyToken: process.env.VUE_APP_CSY_LOGO_PATH,
      numarTel: '',
      formattedPhone: '',
      numberInvalid: true,
      voucherCode: null,
      voucherResponse: null,
      voucherError: null
    }
  },
  methods: {
    checkPhoneOrder(obj) {
      this.numberInvalid = !obj.isValid;
      if (obj.isValid) {
        this.formattedPhone = obj.formattedNumber;
      }
    },
    showProductImageModal(product) {
      this.showProductImage = true;
      this.currentProductModal = product;
    },
    askLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.getPosition);
      }
    },
    getPosition(position) {
      const lat = position.coords.latitude
      const lon = position.coords.longitude
      const apiUrl = process.env.VUE_APP_API_URL;
      axios.get(apiUrl + '/public/get_location/'+lat+'/'+lon).then(response => {
        this.$refs.address_input_autocomplete.$el.value = response.data.route + ' ' + response.data.street_number
        this.orderDetails.city = response.data.locality
        this.orderDetails.zip = response.data.postal_code
      })
    },
    setLocation(location) {
      let street = false
      let streetNo = false
      let city = false
      location.address_components.forEach(entry => {
        if (entry.types.includes('route') && !street) {
          street = entry.long_name
        }
        if (entry.types.includes('street_number') && !streetNo) {
          streetNo = entry.long_name
        }
        if (entry.types.includes('locality') && !city) {
          city = entry.long_name
        }
      })

      this.orderDetails.city = city
      this.orderDetails.street = street
      if (street) {
        this.$refs.address_input_autocomplete.$el.value = street
      }
      if (streetNo) {
        this.$refs.address_input_autocomplete.$el.value += ' ' + streetNo
      }
      this.locationSelected = true
    },
    scrollToCategory() {
      if(window.outerWidth <= 480) {
        var element = document.getElementById("list_menu");
        var top = element.offsetTop;
        window.scrollTo(0, top + 250);
      }
    },
    onScroll(e) {
      this.windowTop = window.top.scrollY /* or: e.target.documentElement.scrollTop */
    },
    getRestaurant() {
      PublicRestaurants.get(this.$route.params.slug).then(response => {
        this.restaurant = response.data.data;
        this.paymentMode = this.restaurant.accept_cash_payment ? 'cash' : 'card';
        if (this.restaurant.stripe_public_key !== null) {
          this.stripe = window.Stripe(this.restaurant.stripe_public_key);
        }
        if (!this.restaurant.is_opened) {
          this.$swal(
            'Doar precomanda',
            'Alege intervalul orar de livrare potrivit pentru tine',
            'warning'
          )
        }
      }).catch(error => {

      })
    },
    async paymentSubmit(e) {
      e.preventDefault();
      const elements = this.payElements
      const {error} = await this.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: 'https://coressy.com/stripe-return',
        },
        redirect: 'if_required'
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (e.g., payment
        // details incomplete)
        const messageContainer = document.querySelector('#error-message');
        messageContainer.textContent = error.message;
      } else {
        this.showPaymentDetailsModal = false;
        this.paymentConfirmed = true
        this.orderNow()
      }
    },
    addToBasket(item) {
      if (item.features && item.features.length) {
        this.showProductFeatures = true
        let currentProduct = JSON.parse(JSON.stringify(item));
        this.selectedProduct = currentProduct
        this.selectedProductInitialState = { ...item }
        return
      }
      item.qty = 1;
      let itemToPush = JSON.parse(JSON.stringify(item));
      let itemFound = this.basket.find(x => x.id === itemToPush.id);
      if (itemFound) {
        itemFound.qty += 1;
        itemFound.initPrice = item.price;
        itemFound.price = itemFound.qty * itemFound.initPrice;
      } else {
        this.basket.push(itemToPush);
      }
      this.$toastr.s('1 x ' + item.name + ' a fost adaugat in comanda');
    },
    checkFeatureRequired(item) {
      let featuresRequired = item.features.filter(x => x.required === true);

      return this.checkMaxAndMinOptions(featuresRequired, 'required');
    },
    checkMaxOptionsOptional(item) {
      let featuresOptional = item.features.filter(x => x.required === false);
      return this.checkMaxAndMinOptions(featuresOptional, 'optional');
    },
    checkMaxAndMinOptions(features, type) {
      let sumCheckedCount = 0;
      let sumMinOptions = 0;
      let sumMaxOptions = 0;
      for (let i = 0; i < features.length; i++) {
        let checkedCountOptions = this.countCheckedOptions(features[i].options);
        sumMinOptions += features[i].min;
        sumMaxOptions += features[i].max;
        sumCheckedCount += checkedCountOptions;
        if (checkedCountOptions < features[i].min && type === 'required') {
          this.$toastr.Add({
            name: features[i].label, // this is give you ability to use removeByName method
            msg: 'Obligatoriu ' + features[i].label, // Toast Message
            timeout: 5000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
            type: "error", // Toast type,
            preventDuplicates: true, //Default is false,
          });
        }
        if (checkedCountOptions > features[i].max) {
          this.$toastr.Add({
            name: features[i].label, // this is give you ability to use removeByName method
            msg: `Alege maxim ${features[i].max} optiuni pentru ${features[i].label}`, // Toast Message
            timeout: 5000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
            type: "error", // Toast type,
            preventDuplicates: true, //Default is false,
          });
        }
      }
      if (sumCheckedCount > sumMaxOptions) {
        return false;
      }

      if (sumCheckedCount < sumMinOptions && type == 'required') {
        return false;
      }
      return true
    },
    addProductFeaturesToBasket() {
      this.selectedProduct.price = this.selectedProductTotalPrice;
      this.selectedProduct.qty = 1;
      let itemToPush = JSON.parse(JSON.stringify(this.selectedProduct));
      let valid = this.checkFeatureRequired(itemToPush);
      if (!valid) {
        return;
      }
      let validMax = this.checkMaxOptionsOptional(itemToPush);
      if (!validMax ) {
        return;
      }
      this.showProductFeatures = false;
      let itemFoundFeature = this.basket.filter(x => x.id === itemToPush.id);

      let findSameFeatures = true;
      if (itemFoundFeature.length) {
        for (let i = 0; i < itemFoundFeature.length; i++) {
          if (isEqual(itemFoundFeature[i].features,itemToPush.features)) {
            itemFoundFeature[i].qty += 1;
            itemFoundFeature[i].initPrice = itemToPush.price;
            itemFoundFeature[i].price = itemFoundFeature[i].qty * itemFoundFeature[i].initPrice;
            findSameFeatures = false;
          }
        }
      }

      if (findSameFeatures) {
        this.basket.push(itemToPush);
      }
    },
    removeFromBasket(item, index) {
      this.selectedProduct = false
      this.basket.splice(index, 1);
    },
    orderNow() {
      if (this.userInfo === null) {
        bus.$emit('showLogin')
        return
      }
      if (this.numberInvalid) {
        this.$swal(
            'Atentie',
            'Numar telefon invalid',
            'warning'
        );
        return
      }
      if (this.basket.length === 0) {
        this.$swal(
            'Atentie',
            'Nu exista produse adaugate in comanda',
            'warning'
        );
        return
      }
      this.orderDetails.street = this.$refs.address_input_autocomplete.$el.value
      this.orderDetails.phone = this.formattedPhone;
      let orderData = {
        restaurant_id: this.restaurant.id,
        products: this.basket,
        order_details: this.orderDetails,
        order_mode: this.orderMode,
        extra_delivery_confirmed: this.extra_delivery_confirmed,
        payment_method: this.paymentMode,
        payment_confirmed: this.paymentConfirmed,
        created_by_restaurant: false
      }

      if (this.voucherResponse && !this.voucherError) {
        orderData.voucher_code = this.voucherCode;
      }

      if (!this.restaurant.is_opened) {
        orderData.preorder_date = this.restaurant.next_open_day.date + ' ' + this.preorderTime;
      }
      if (this.sms_code !== null) {
        orderData.sms_code = this.sms_code
      }
      if (this.paymentConfirmed) {
        orderData.payment_intent_id = this.paymentIntentId;
      }
      this.isBusy = true;
      Orders.store(orderData).then(response => {
        this.basket = []
        this.isBusy = false;
        this.extra_delivery_confirmed = false;
        this.paymentMode = 'cash';
        this.payElements = null;
        this.paymentConfirmed = false;
        this.paymentIntentId = null;

        this.$router.push({ name: 'customer.orders.last' })
      }).catch(async error => {
        this.isBusy = false;
        if (error.response.data.type === 'order_details') {
          this.$swal('Atentie', 'Pentru a putea comanda va rugam sa completati campurile obligatorii', 'warning')
        } else if (error.response.data.type === 'min_order_amount') {
          this.$swal('Comanda minima: ' + this.toCurrency(this.restaurant.min_order_amount_value) + ' (exclus livrare)', 'Acest restaurant accepta doar comenzi cu valoarea produselor de peste ' + this.toCurrency(this.restaurant.min_order_amount_value), 'warning')
        } else if (error.response.data.type === 'address_not_found') {
          this.$swal('Adresa nu poate fi gasita', 'Adresa introdusa nu poate fi gasita', 'warning')
        } else if (error.response.data.type === 'max_delivery_range_reached') {
          this.$swal('Nu se poate livra la aceasta adresa', 'Adresa unde doriti livrarea produselor este inafara ariei de acoperire a acestui restaurant', 'warning')
        } else if (error.response.data.type === 'delivery_extra_price') {
          const message = "Adresa unde doriti livrarea produselor are un cost suplimentar de "
              + this.toCurrency(error.response.data.extra_delivery_tax) +
              "<br>Totalul comenzii va fi in valoare de " +
              this.toCurrency(parseFloat(this.basketTotals.total) + parseFloat(error.response.data.extra_delivery_tax));

          this.$swal({
            title: 'Atentie !!!',
            html: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'DA, CONFIRM COMANDA',
            cancelButtonText: 'NU, RENUNT',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.extra_delivery_confirmed = true;
              this.$nextTick().then(() => {
                this.orderNow();
              })
            }
          })
        } else if (error.response.data.type === 'check_phone') {
          const { value: number } = await this.$swal({
            title: 'Verificare SMS',
            input: 'number',
            inputLabel: 'Introduceti codul primit prin SMS',
            inputPlaceholder: 'XXXX',
            icon: 'info',
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: 'VALIDARE (TRIMITE COMANDA)',
            showCancelButton: true,
            cancelButtonText: 'ANULARE',
            inputValidator: (value) => {
              if (!value) {
                return 'Trebuie sa introduceti codul primit prin SMS'
              }
            }
          })
          if (number) {
            this.sms_code = number
            this.orderNow();
          }
        } else if(error.response.data.type === 'check_phone_wrong_sms_code') {
          this.sms_code = null
          this.$swal(
              'Cod gresit',
              'Codul primit prin sms a fost introdus gresit. Va rugam sa incercati din nou',
              'error'
          )
        } else if(error.response.data.type === 'check_phone_sms_error') {
          this.$swal(
              'Atentie',
              'Din pacate nu s-a putut efectua trimiterea sms-ului catre numarul dvs. de telefon',
              'error'
          )
        } else if(error.response.data.type === 'verify_email') {
          this.$swal(
              'Verificare email',
              'Va rugam sa verificati adresa de email apasand click pe link-ul primit prin email',
              'error'
          )
        } else if(error.response.data.type === 'payment_required') {
          this.showPaymentDetailsModal = true
          const clientSecret = error.response.data.intent.client_secret
          this.paymentIntentId = error.response.data.intent.id;
          const options = {
            clientSecret: clientSecret,
            // Fully customizable with appearance API.
            appearance: {/*...*/},
          };

          // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
          this.payElements = this.stripe.elements(options);

          // Create and mount the Payment Element
          this.$nextTick(() => {
            const paymentElement = this.payElements.create('payment');
            paymentElement.mount('#payment-element');
          })
        } else if(error.response.data.type === 'voucher_invalid') {
          this.$swal(
              'Cod cupon invalid',
              'Codul cuponului introdus nu mai este de actualitate',
              'error'
          )
          this.voucherResponse = null;
          this.voucherError = 'Cuponul introdus nu mai este de actualitate';
          this.addVoucher();
        } else if(error.response.data.type === 'force_card_payment') {
          this.$swal(
              'Doar plata card',
              'Pentru a putea plasa aceasta comanda va trebui sa alegeti metoda de plata prin card',
              'error'
          )
        }
      })
    },
    sendVerificationMail() {
      const apiUrl = process.env.VUE_APP_API_URL;
      axios.post(apiUrl + '/public/send_verification_mail').then(response => {
        this.verificationMailSent = true
      })
    },
    checkUser() {
      if (this.userInfo !== null) {
        const apiUrl = process.env.VUE_APP_API_URL;
        axios.post(apiUrl + '/public/me').then(response => {
          localStorage.setItem('userInfo', JSON.stringify(response.data.user_data))
          this.userInfo = response.data.user_data
          if (this.userInfo.verified) {
            clearInterval(this.userCheckTimeout)
          }
        })
      }
    },
    featureRadioChanged(featureId, selectedName) {
      let featureData = this.selectedProduct.features.find(x => x.id === featureId);
      featureData.options.forEach(entry => {
        if (entry.name !== selectedName) {
          entry.checked = false
        } else {
          entry.checked = true
        }
      })
    },
    changeQtyOptionFeature(feature, option, val) {
      let featureData = this.selectedProduct.features.find(x => x.id === feature.id);
      featureData.options.forEach(entry => {
        if (entry.name === option.name) {
          let newVal = parseInt(option.qty) + val;
          entry.checked = true;
          if (newVal < 1) {
            entry.qty = 0;
            entry.checked = false;
          } else if (newVal > option.max) {
            entry.qty = option.max;
          } else {
            entry.qty = parseInt(option.qty) + val;
          }
        }
      })
    },
    changeQtyBasket(index, val) {
      let newQty = this.basket[index].qty + val;
      let initialPrice = this.basket[index].price / this.basket[index].qty;
      if (newQty < 1) {
        return;
      }
      if (val === -1) {
        this.basket[index].price = this.basket[index].price - initialPrice;
      } else {
        this.basket[index].price = this.basket[index].price + initialPrice;
      }
      this.basket[index].qty = newQty;
    },
    checkRequiredFeature(feature) {
      let countOptionsChecked = this.countCheckedOptions(feature.options);
      if (!feature.required) {
        return `Optional Max: ${feature.max} - Selectat: ${countOptionsChecked}`;
      }
      return feature.required ? `Obligatoriu Min: ${feature.min} / Max: ${feature.max} - Selectat: ${countOptionsChecked}`: '';
    },
    countCheckedOptions(options) {
      let optionsChecked = options.filter(x => x.checked === true);
      return optionsChecked.length;
    },
    addVoucher() {
      if (this.voucherCode === '') {
        return;
      }
      Orders.addVoucher(this.voucherCode, this.restaurant.id).then(response => {
        this.voucherResponse = response.data.data
      }).catch(error => {
        if (error.response.data.type === 'voucher_not_found') {
          this.voucherError = 'Cuponul introdus nu este valid';
        } else if(error.response.data.type === 'voucher_expired') {
          this.voucherError = 'Cuponul introdus a expirat la data de ' + error.response.data.expired_on;
        } else if(error.response.data.type === 'voucher_already_used') {
          this.voucherError = 'Acest cupon l-ati folosit deja in alta comanda';
        } else if(error.response.data.type === 'voucher_not_for_city') {
          this.voucherError = 'Acest cupon nu este disponibil in orasul unde se afla acest restaurant';
        }
      })
    }
  },
  mounted() {
    if (this.userInfo !== null && this.userCheckTimeout === null) {
      this.userCheckTimeout = setInterval(() => {
        this.checkUser();
      }, 2000);
    }
    bus.$on('loggedIn', data => {
      this.userInfo = data
      if (this.userCheckTimeout === null) {
        this.userCheckTimeout = setInterval(() => {
          this.checkUser();
        }, 2000);
      }
    })
    this.getRestaurant();
    window.addEventListener("scroll", this.onScroll)
    //this.askLocation()
  },
  computed: {
    ridicarePersonala() {
      return parseInt(process.env.VUE_APP_RIDICARE);
    },
    orderDetails() {
      let userData = {
        firstName: '',
        lastName: '',
        street: '',
        address_extra: '',
        city: '',
        zip: '',
        phone: '',
        customer_notes: ''
      }
      if(this.userInfo !== null) {
        userData = {
          firstName: this.userInfo.first_name,
          lastName: this.userInfo.last_name,
          street: this.userInfo.address,
          city: this.userInfo.city,
          zip: this.userInfo.zip,
          phone: this.userInfo.phone
        }
      }
      return userData
    },
    basketTotals() {
      let returnData = {
        subtotal: 0,
        delivery: 0,
        total: 0
      }
      if(this.basket.length === 0) {
        return returnData
      }
      this.basket.forEach(item => {
        returnData.subtotal = returnData.subtotal + item.price
      })
      if (this.orderMode === '0') {
        returnData.delivery = this.restaurant.delivery_tax
      }
      returnData.total = returnData.subtotal + returnData.delivery

      if (this.voucherResponse) {
        if (returnData.total < this.voucherResponse.min_order_value) {
          this.voucherError = 'Cuponul poate fi folosit doar la comenzi de peste ' + this.voucherResponse.min_order_value + ' RON';
          this.voucherResponse = null;
        } else {
          this.voucherError = null;
        }
        if (!this.voucherResponse) {
          return returnData;
        }
        if (this.voucherResponse.value_type === 'fixed') {
          returnData.total = returnData.total - this.voucherResponse.value;
        } else {
          const discountAmount = (this.voucherResponse.value / 100) * returnData.total;
          returnData.total = returnData.total - discountAmount
        }
      }
      return returnData
    },
    preorderTimes() {
      const times = [];
      let startTime = this.restaurant.next_open_day.time.split(':')[0];
      let endTime = this.restaurant.next_open_day.open_until.split(':')[0];
      this.preorderTime = startTime + ":00"
      for (let hr = startTime; hr < endTime; hr++) {
        times.push({
          value: hr + ':00',
          text: hr + ':00 - ' + (parseFloat(hr) + 1) + ":00",
        })
      }
      return times;
    },
    selectedProductTotalPrice() {
      if (!this.selectedProduct) {
        return 0
      }
      let extraPrices = []
      this.selectedProduct.features.forEach((entry, i) => {
        entry.options.forEach((option, ii) => {
          if (option.multiple) {
            if (option.qty * option.price > 0) {
              extraPrices.push(parseFloat(option.qty) * parseFloat(option.price))
            }
          } else {
            if (option.checked) {
              extraPrices.push(parseFloat(option.price))
            }
          }
        })
      })
      const extraPricesTotal = parseFloat(extraPrices.reduce((a, b) => a + b, 0));
      const initialProductPrice = parseFloat(this.selectedProductInitialState.price)
      return initialProductPrice + extraPricesTotal
    },
  },
  metaInfo() {
    return {
      title: 'Comanda la ' + this.restaurant.name + ' - Coressy delivery livreaza pentru tine',
      meta: [
        { name: 'description', content: 'Comanda online produse de la ' + this.restaurant.name},
        { property: 'og:title', content: 'Comenzi online la ' + this.restaurant.name},
        { property: 'og:site_name', content: 'Coressy Delivery'},
        { property: 'og:description', content: 'Comanda preparate gustoase de la ' + this.restaurant.name + 'iar CORESSY delivery ti le va livra in cel mai scurt timp'},
       /* { property: 'og:type', content: 'profile'}, */
       /* { property: 'og:url', content: '' }, */
        { property: 'og:image', content: this.restaurant.image_url }
      ]
    }
  }
}
</script>

<style>
.flex-md-column {
  flex: 1;
}
.wrapper-qty {
  display: flex;
  justify-content: space-around;
}
.wrapper-qty .btn.btn-outline-secondary {
  border: none;
}
.wrapper-qty .qty {
  width: 42px;
  opacity: 1;
}
.option-label {
  font-size: 1rem;
}
.option-label span, .checkboxed-btn span, .custom-radio span {
  color: #5273C0;
}
.feature-label {
  margin-bottom: 0px;
}
.feature-label .col-form-label {
  font-weight: bold;
  font-size: 1.5rem;
}
.form-checkboxes {
  margin-bottom: 1rem;
}
.custom-control label {
  margin-bottom: 0;
}
.checkboxed-btn {
  font-size: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.checkboxed-btn .custom-control-label {
  font-weight: normal;
}

.custom-checkbox .custom-control-input {
  width: 28px;
  height: 28px;
  margin-right: 10px;
  position: relative;
  border-radius: 50px;
}

.custom-radio {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 1rem;
}
.custom-radio .custom-control-label {
  font-size: 1rem;
}
.custom-radio .custom-control-input {
  width: 28px;
  height: 28px;
  position: relative;
  margin-right: 10px;
}

.btn-outline-secondary,
.btn-outline-secondary.disabled {
  color: #5273C0;
  border-color: #5273C0;
}
.btn-outline-secondary:hover, .btn-outline-secondary.active {
  background-color: #5273C0;
  border-color: #5273C0;
}
.product-feature {
  width: 100%;
}
.product-options-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
}
.option-multiple {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-device-width: 480px) {
  .productsFirstTDMaxWidth {
    max-width: 100px !important;
  }
  .categoriesTabs {
    position: fixed;
    top: 91px;
    left: 0;
    z-index: 5;
  }
}
.pac-container {
  background-color: #FFF;
  z-index: 9999 !important;
  position: fixed;
  display: inline-block;
  float: left;
}
.modal{
  z-index: 20 !important;
}
.modal-backdrop{
  z-index: 10 !important;
}
#text-promo {
  left: 0px;
  top: 15px;
  padding: 5px 10px;
  background: red;
  color: white;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
}
.nav-pills .nav-link, .alert, .swal2-popup, .swal2-styled {
  border-radius: 0!important;
}
#image-product {
  cursor: pointer;
}
.modal-footer-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#modal-image-product .modal-dialog,
#modal-options-product .modal-dialog {
  margin-top: 50px;
}
.product-details {
  width: 100%;
}
.product-cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    margin: 10px 0px;
    border-bottom: 1px solid #5273C0;
}
.item-name {
  font-size: 1rem;
  color: #5273C0;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.qty-total {
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
}
.total-price-item {
  margin-left: 15px;
}
.total-price-item span {
  color: #5273C0;
  font-weight: bold;
}
#modal-image-product .modal-dialog {
  margin-top: 50px;
}
.lastOption {
  margin-bottom: 0px;
}
</style>
